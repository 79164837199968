import React, { Component } from 'react'
import { Link } from 'gatsby'

import Logo from './logo'
import { Search, Close } from './icons'

class Header extends Component {

  lastScroll = 0

  state = {
    visible: true,
    offCanvas: false,
    menuMobile: '',
    servicesActive: false,
    dark: false,
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  scrollDetect = () => {
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
    let { visible, scrolled } = this.state

    if (currentScroll > window.innerHeight && !scrolled) {
      this.setState({ scrolled: true })
    } else if (currentScroll <= window.innerHeight && scrolled) {
      this.setState({ scrolled: false })
    }

    if (currentScroll > 0 && this.lastScroll <= currentScroll) {
      visible && this.setState({ visible: false })
    } else {
      !visible && this.setState({ visible: true })
    }
    this.lastScroll = currentScroll
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas, menuMobile: '', servicesActive: false })
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false, menuMobile: '', servicesActive: false })
  }

  _renderMenu(props) {
    let services = {
      href: '/services/',
      onClick: (event) => {
        event.preventDefault()
        this.setState({ servicesActive: !this.state.servicesActive })
      },
    }
    return (
      <ul>
        <li><Link to='/about/' {...props}>About</Link></li>
        <li><a {...services}>Services</a></li>
        <li><Link to='/work/' {...props}>Work</Link></li>
        <li><Link to='/blog/' {...props}>Blog</Link></li>
        <li><Link to='/contact/' {...props}>Contact</Link></li>
      </ul>
    )
  }

  render() {
    let { offCanvas, dark, servicesActive, visible, scrolled } = this.state
    let { allWordpressCategory, allWordpressWpSector } = this.props.data

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active',
    }

    let headerClasses = `
      header
      ${offCanvas ? 'header--offcanvas' : ''}
      ${dark ? 'header--dark' : ''}
      ${servicesActive ? 'header--services-active' : ''}
      ${visible || servicesActive || offCanvas ? 'visible' : ''}
      ${scrolled ? '' : 'header--transparent'}
    `.replace(/\n/g, '')

    return (
      <>
        <div className='header__padding' />
        <header className={headerClasses} role='banner'>

          <div className='header__inner container'>
            <Link to='/' className='header__logo' onClick={this._hideOffCanvas}>
              <Logo colour={dark ? '#FFF' : '#000'} />
            </Link>
            <nav className="header__nav">
              { this._renderMenu(props) }
            </nav>
            <button className='header__search'>
              <Link to='/search'>
                <span>Search</span>
                <Search colour={dark ? '#FFF' : '#000'} />
              </Link>
            </button>
            <button className='header__close' onClick={() => window.history.back()}>
              <Close colour="#737373" />
            </button>
            <button className={`hamburger ${offCanvas ? 'active' : ''}`} onClick={this._toggleOffCanvas}>
              <span className='lines'></span>
            </button>
          </div>

          <div className={servicesActive ? 'header__services active' : 'header__services'}>
            <div className="header__services__inner container">
              <div className="col" />
              <div className="col">
                <h4>What we do</h4>
                <ul>
                  {
                    allWordpressCategory.edges && allWordpressCategory.edges.map((el, i) => {
                      let props = {
                        to: `/services/${el.node.slug}`,
                        onClick: this._hideOffCanvas,
                      }
                      return (
                        <li key={i}>
                          <Link {...props}>{ el.node.name }</Link>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
              <div className="col">
                <h4>Where we do it <span className="close" onClick={() => this.setState({ servicesActive: !servicesActive })}><Close colour="#737373" /></span></h4>
                <ul>
                  {
                    allWordpressWpSector.edges && allWordpressWpSector.edges.map((el, i) => {
                      let props = {
                        to: `/services/${el.node.slug}`,
                        onClick: this._hideOffCanvas,
                      }
                      return (
                        <li key={i}>
                          <Link {...props}>{ el.node.name }</Link>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
        </header>
        <div className={ `${offCanvas ? 'off-canvas active' : 'off-canvas'}  ${dark && 'off-canvas--dark'}` }>
          <nav className={servicesActive ? 'off-canvas__nav services-active' : 'off-canvas__nav'}>
            { this._renderMenu(props) }
          </nav>
          <div className={servicesActive ? 'off-canvas__services active' : 'off-canvas__services'}>
            <div className="off-canvas__services__inner container">
              <h4>What we do</h4>
              <ul>
                {
                  allWordpressCategory.edges && allWordpressCategory.edges.map((el, i) => {
                    let props = {
                      to: `/services/${el.node.slug}`,
                      onClick: this._hideOffCanvas,
                    }
                    return (
                      <li key={i}>
                        <Link {...props}>{ el.node.name }</Link>
                      </li>
                    )
                  })
                }
              </ul>
              <h4>Where we do it</h4>
              <ul>
                {
                  allWordpressWpSector.edges && allWordpressWpSector.edges.map((el, i) => {
                    let props = {
                      to: `/services/${el.node.slug}`,
                      onClick: this._hideOffCanvas,
                    }
                    return (
                      <li key={i}>
                        <Link {...props}>{ el.node.name }</Link>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
          <button className={servicesActive ? 'off-canvas__search active' : 'off-canvas__search'}>
            <Link to='/search' onClick={this._hideOffCanvas}>
              <Search colour={dark ? '#969696' : '#737373'} />
              <span>Search</span>
            </Link>
          </button>
          <div className={servicesActive ? 'off-canvas__contact active' : 'off-canvas__contact'}>
            <p>© Ellis Jones<br /><br />
            <a href="tel:+61394160046">+61 (03) 9416 0046</a><br />
              26 Peel St<br />
              Collingwood VIC 3066,<br />
              Melbourne, VIC, Australia</p>
          </div>
        </div>
      </>
    )
  }
}

export default Header
