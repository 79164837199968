// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-search-js": () => import("/opt/build/repo/src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-page-js": () => import("/opt/build/repo/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-blog-js": () => import("/opt/build/repo/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-careers-js": () => import("/opt/build/repo/src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-work-js": () => import("/opt/build/repo/src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */),
  "component---src-templates-team-js": () => import("/opt/build/repo/src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */),
  "component---src-templates-services-js": () => import("/opt/build/repo/src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-post-js": () => import("/opt/build/repo/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-project-js": () => import("/opt/build/repo/src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-404-js": () => import("/opt/build/repo/src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

