import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../components/header'
import Footer from '../components/footer'
//import Transition from '../components/transition'

import '../assets/scss/main.scss'

const Layout = ({ children, location, pageContext }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allWordpressCategory(sort: {fields: [menu_order], order: ASC}) {
          edges {
            node {
              name
              slug
            }
          }
        }
        allWordpressWpSector(sort: {fields: [menu_order], order: ASC}) {
          edges {
            node {
              name
              slug
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Header data={data} location={location} pageContext={pageContext} />
        <main>
          { children }
        </main>
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
