import React, { Component } from 'react'

class Footer extends Component {

  render() {
    return (
      <section className="footer">
        <div className="footer__inner container">
          <div className="col col--1of3">
            <h2>© Ellis Jones</h2>
          </div>
          <div className="col col--2of3 footer__inner-wrap">
            <div className="left">
              <p><a href="tel:61394160046">+61 (03) 9416 0046</a><br />
              26 Peel St<br />
              Collingwood VIC 3066,<br />
              Melbourne, VIC, Australia</p>
            </div>
            <div className="right">
              <ul>
                <li><a href="https://www.linkedin.com/company/ellis-jones/" target='_blank' rel='nofollow noopener noreferrer'>LinkedIn</a></li>
                <li><a href="https://www.instagram.com/ellisjonesagency/" target='_blank' rel='nofollow noopener noreferrer'>Instagram</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer__inner container">
          <div className="col col--1of3">
            &nbsp;
          </div>
          <div className="col col--2of3">
            <p className="kulin">Ellis Jones acknowledges the Indigenous people of Australia as the traditional custodians of the country on which we live and work. We value their traditions, customs and culture, and pay our respect to their Elders past, present and emerging. We walk with you.</p>
          </div>
        </div>
      </section>
    )
  }
}

export default Footer
