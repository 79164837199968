import React, { Component } from 'react'

class Search extends Component {
  render() {
    let { colour } = this.props

    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px">
        <path fill={colour} d="M22,20.6l-6-6c1.2-1.5,2-3.5,2-5.6c0-5-4-9-9-9S0,4,0,9s4,9,9,9c2.1,0,4.1-0.7,5.6-2l6,6L22,20.6z M9,16c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7S12.9,16,9,16z"/>
      </svg>
    )
  }
}

class ArrowDown extends Component {
  render() {
    let { colour } = this.props

    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.443 10.879" {...this.props}>
        <path fill="none" stroke={colour} strokeWidth="2px" d="M-2675,90.91l9.544,8.784,9.544-8.784" transform="translate(2675.677 -90.174)"/>
      </svg>
    )
  }
}

class FormButton extends Component {
  render() {
    let { colour } = this.props

    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103.38 85.064" {...this.props}>
        <path fill={colour} d="M0,3.931,60.469,0l2.854,43.023,1.831.162V89.977L4.523,90.3,4.415,38.608,2.1,38.338Z" transform="translate(103.38 21.845) rotate(104)"/>
      </svg>
    )
  }
}

class Close extends Component {
  render() {
    let { colour } = this.props
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.037 16.037" {...this.props}>
        <g transform="translate(-162.041 -304.876) rotate(45)">
          <rect fill={colour} width="21" height="1.68" transform="translate(331 100.16)"/>
          <rect fill={colour} width="21" height="1.68" transform="translate(342.34 90.5) rotate(90)"/>
        </g>
      </svg>
    )
  }
}

class Facebook extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" {...this.props}>
        <path fill="#737373" d="M136.7.114a17,17,0,1,0,17,17A17.006,17.006,0,0,0,136.7.114Zm5,11h-3s-.9-.462-1,1v3h4l-1,3h-3v9h-3v-9h-3v-3h3v-3s-.536-4.558,3-5h4Z" transform="translate(-119.699 -0.114)"/>
      </svg>
    )
  }
}

class Twitter extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" {...this.props}>
        <path fill="#737373" d="M17,.114a17,17,0,1,0,17,17A17.006,17.006,0,0,0,17,.114Zm9,13v1a11.92,11.92,0,0,1-12,12,10.329,10.329,0,0,1-6-2,9.81,9.81,0,0,0,1,0,8.082,8.082,0,0,0,5-2,4.395,4.395,0,0,1-4-3,9.811,9.811,0,0,0,1,0,4.915,4.915,0,0,0,1,0c-1.938-.374-4-1.96-4-4H8c.578.306,1.32,0,2,0-1.122-.748-2-1.538-2-3,0-.782.626-1.388,1-2,2.074,2.55,5.5,3.83,9,4a4.911,4.911,0,0,1,0-1,3.856,3.856,0,0,1,4-4,3.771,3.771,0,0,1,3,1,11.128,11.128,0,0,0,3-1,6.179,6.179,0,0,1-2,3c.85-.1,1.252-.694,2-1A7.459,7.459,0,0,1,26,13.114Z" transform="translate(0 -0.114)"/>
      </svg>
    )
  }
}

class LinkedIn extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" {...this.props}>
        <path fill="#737373" d="M256.4-.186a17,17,0,1,0,17,17A17.006,17.006,0,0,0,256.4-.186Zm-4,24h-4v-11h4Zm-2-13a1.9,1.9,0,0,1-2-2,2,2,0,0,1,4,0A1.937,1.937,0,0,1,250.4,10.814Zm15,13h-3v-6s.006-2.272-2-2c0,0-1.8-.21-2,2v6h-4v-11h3v1a5.132,5.132,0,0,1,4-2s3.8.308,4,5v7Z" transform="translate(-239.4 0.186)"/>
      </svg>
    )
  }
}

class Email extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" {...this.props}>
        <path fill="#737373" d="M375.8,0a17,17,0,1,0,17,17A17.006,17.006,0,0,0,375.8,0Zm9,11-9,7-9-7Zm1,12c0,.374-.592,0-1,0h-18c-.374,0-1,.408-1,0V12l9,8c.136.1.864,0,1,0s-.1.1,0,0l10-8V23Z" transform="translate(-358.801)"/>
      </svg>
    )
  }
}

class Communications extends Component {
  render() {
    let { colour } = this.props
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.65 25.49">
        <path fill={colour} d="M3.5,25.49H23.541A4.114,4.114,0,0,0,27.65,21.38V1.029A1.032,1.032,0,0,0,26.621,0H5.961A1.032,1.032,0,0,0,4.932,1.029V11.836h-3.9A1.032,1.032,0,0,0,0,12.865v9.127A3.5,3.5,0,0,0,3.5,25.49Zm3.492-3.5V2.057h18.6V21.38a2.054,2.054,0,0,1-2.052,2.052H6.681A3.463,3.463,0,0,0,6.99,21.992Zm-4.932-8.1H4.932v8.1a1.437,1.437,0,1,1-2.875,0v-8.1Z" transform="translate(0 0)"/>
        <path fill={colour} d="M174.453,284.5H162.429a1.029,1.029,0,0,0,0,2.057h12.025a1.029,1.029,0,0,0,0-2.057Z" transform="translate(-152.176 -268.24)"/>
        <path fill={colour} d="M174.453,204.5H162.429a1.029,1.029,0,1,0,0,2.057h12.025a1.029,1.029,0,0,0,0-2.057Z" transform="translate(-152.176 -192.813)"/>
        <path fill={colour} d="M162.429,116.557h6.309a1.029,1.029,0,1,0,0-2.057h-6.309a1.029,1.029,0,1,0,0,2.057Z" transform="translate(-152.176 -107.956)"/>
      </svg>
    )
  }
}


export { Search, ArrowDown, FormButton, Close, Facebook, Twitter, LinkedIn, Email, Communications }
